import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IntercomProvider as ReactUseIntercomProvider,
  useIntercom as useReactUseIntercom,
} from "react-use-intercom";

export type IntercomUserData = {
  /** The user id of the current user. */
  userId: string;

  /** Hash provided by the CoreAPI to authenticate the userId. */
  userHash: string;
};

export type IntercomContextData = {
  /** Shows the intercom messenger. */
  showMessenger(): void;

  /** Closes the intercom messenger. */
  hideMessenger(): void;

  /** The number of unread messages. */
  unreadMessagesCount: number;
};

/** A dialog context which provides the components with the function to create a dialog */
export const IntercomContext = createContext<IntercomContextData | undefined>(
  undefined,
);

export type IntercomProviderProps = {
  /** Whether intercom should be enabled. */
  enabled: boolean;

  /** The intercom app ID to use (= API key). If not defined, intercom will not be loaded. */
  appId?: string;

  /** Optional user id to  */
  user?: IntercomUserData;

  /**
   * Callback, when the user requests to show, but intercom is not enabled (e.g. when cookie is not accepted).
   * Should return true, when intercom has been enabled by the action.
   *
   * The return value indicates, whether intercom should be automatically opened, after it has been loaded.
   */
  onRequestEnable?(): Promise<boolean> | boolean;
};

/**
 *  @returns A context provider to allow usage of intercom
 */
export function IntercomProvider({
  children,
  enabled,
  appId,
  user,
  onRequestEnable,
}: PropsWithChildren<IntercomProviderProps>): JSX.Element {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  if (!appId) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <ReactUseIntercomProvider
      appId={appId}
      onUnreadCountChange={setUnreadMessagesCount}
      autoBoot={false}
      // A small initialization delay prevents a console warning about missing app ids in the dev environment.
      // see https://github.com/devrnt/react-use-intercom/issues/673
      initializeDelay={2000}
    >
      <IntercomProviderInternal
        enabled={enabled}
        unreadMessagesCount={unreadMessagesCount}
        user={user}
        onRequestEnable={onRequestEnable}
      >
        {children}
      </IntercomProviderInternal>
    </ReactUseIntercomProvider>
  );
}

type IntercomProviderInternalProps = Pick<
  IntercomProviderProps,
  "enabled" | "user" | "onRequestEnable"
> &
  Pick<IntercomContextData, "unreadMessagesCount">;

function IntercomProviderInternal({
  enabled,
  unreadMessagesCount,
  user,
  onRequestEnable,
  children,
}: PropsWithChildren<IntercomProviderInternalProps>): JSX.Element {
  const {
    boot,
    hardShutdown,
    update,
    show: showInternal,
    hide,
  } = useReactUseIntercom();

  const [isBooted, setIsBooted] = useState(false);

  const [hasUserRequestedOpen, setHasUserRequestedOpen] = useState(false);

  // Enables/Disables intercom, when the enabled flag changes.
  useEffect(() => {
    if (enabled) {
      boot({
        hideDefaultLauncher: true,
      });
    } else {
      hardShutdown();
    }
    setIsBooted(enabled);
    // The boot function should only be called when the enabled flag changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  // Updates the intercom user
  useEffect(() => {
    if (isBooted) {
      update({
        userId: user?.userId,
        userHash: user?.userHash,
      });
    }
  }, [user?.userId, user?.userHash, update, isBooted]);

  const show = useCallback(async () => {
    if (!enabled) {
      const shouldOpenAfterLoad = await onRequestEnable?.();
      setHasUserRequestedOpen(shouldOpenAfterLoad ?? false);
      return;
    }

    showInternal();
  }, [enabled, onRequestEnable, showInternal]);

  // Effect to show intercom once it is enabled, after the user requested it.
  useEffect(() => {
    if (hasUserRequestedOpen && enabled && isBooted) {
      showInternal();
      setHasUserRequestedOpen(false);
    }
  }, [enabled, hasUserRequestedOpen, isBooted, showInternal]);

  const value = useMemo(
    () => ({ unreadMessagesCount, showMessenger: show, hideMessenger: hide }),
    [unreadMessagesCount, show, hide],
  );

  return (
    <IntercomContext.Provider value={value}>
      {children}
    </IntercomContext.Provider>
  );
}

/**
 * @returns Hook that provides access to the intercom context. Can be undefined, if Intercom is not available in the current environment.
 */
export function useIntercom(): IntercomContextData | undefined {
  return useContext(IntercomContext);
}
