import { assert } from "@faro-lotv/foundation";
import { PropsWithChildren } from "react";
import {
  CookieId,
  useIsCookieAcceptedReactive,
  useShowAcceptCookieDialog,
} from "../cookie-manager";
import { IntercomProvider, IntercomProviderProps } from "./intercom-context";

type IntercomProviderWithCookieIntegrationProps = Pick<
  IntercomProviderProps,
  "appId" | "user"
>;

/**
 * @returns A Provider of the IntercomContext, that is integrated with the cookie-manager from foreign-observers.
 */
export function IntercomProviderWithCookieIntegration({
  children,
  ...intercomProviderProps
}: PropsWithChildren<IntercomProviderWithCookieIntegrationProps>): JSX.Element {
  const isCookieAccepted = useIsCookieAcceptedReactive(CookieId.intercom);
  const requestAcceptCookie = useShowAcceptCookieDialog();

  return (
    <IntercomProvider
      enabled={isCookieAccepted}
      onRequestEnable={() => {
        assert(
          requestAcceptCookie,
          "Expected Intercom to only be shown, when cookie-manager is available.",
        );

        requestAcceptCookie(CookieId.intercom);
        // Assume that the cookie was accepted here, and automatically open Intercom as soon as its loaded.
        return true;
      }}
      {...intercomProviderProps}
    >
      {children}
    </IntercomProvider>
  );
}
