import { assert } from "@faro-lotv/foundation";
import { useCallback, useContext, useEffect, useState } from "react";
import { HBCookieManagerContext } from "./cookie-manager";
import { CookieId, HolobuilderCookieManager } from "./cookie-manager-types";

/**
 * @returns The HolobuilderCookieManager instance
 *
 * Note: kept only local in order to not expose too much of the cookie-manager outside the package
 */
function useHBCookieManager(): HolobuilderCookieManager | undefined {
  const cookieManagerContext = useContext(HBCookieManagerContext);
  assert(
    cookieManagerContext,
    "useCookieManager is only available inside a CookieManagerProvider",
  );

  if (!window.HolobuilderCookieManager && cookieManagerContext.isManagerUsed) {
    // Having a debug message in the logs will allow devs to understand why the cookie-manager might not work as expected
    // eslint-disable-next-line no-console
    console.debug("cookie-manager is not available yet");
  }

  return window.HolobuilderCookieManager;
}

/**
 * @returns True if the cookie-manager has been initialized (i.e., all functionality is available)
 */
export function useIsCookieManagerInitialized(): boolean {
  const cookieManagerContext = useContext(HBCookieManagerContext);
  assert(
    cookieManagerContext,
    "useIsCookieManagerInitialized is only available inside a CookieManagerProvider",
  );

  return cookieManagerContext.isInitialized;
}

/**
 * @returns A method for checking if a specific cookie is accepted
 */
export function useIsCookieAccepted(): (id: CookieId | string) => boolean {
  const cookieManagerContext = useContext(HBCookieManagerContext);
  assert(
    cookieManagerContext,
    "useIsCookieAccepted is only available inside a CookieManagerProvider",
  );

  const cookieManager = useHBCookieManager();

  return useCallback(
    (id: string) => {
      if (!cookieManager) return false;
      return cookieManager.isCookieAccepted(id);
    },
    [cookieManager],
  );
}

/**
 * Frequency of cookie updates via polling in ms.
 * Needs to be fast enough to guarantee a smooth experience after cookies have been accepted.
 */
const REACTIVE_UPDATE_INTERVAL = 2000;

/**
 * A version of useIsCookieAccepted that provides a reactive boolean value.
 * Uses a timer internally to update the value.
 *
 * @param id The cookie id to check
 * @returns Whether the cookie is accepted
 */
export function useIsCookieAcceptedReactive(id: CookieId): boolean {
  const isCookieAccepted = useIsCookieAccepted();

  const [isAccepted, setIsAccepted] = useState(isCookieAccepted(id));

  useEffect(() => {
    const interval = setInterval(() => {
      const newAccepted = isCookieAccepted(id);

      if (newAccepted !== isAccepted) setIsAccepted(newAccepted);
    }, REACTIVE_UPDATE_INTERVAL);

    return () => clearInterval(interval);
  }, [id, isAccepted, isCookieAccepted]);

  return isAccepted;
}

/**
 * @returns A method for opening the cookie-manager
 */
export function useShowCookieManager(): () => void {
  const cookieManagerContext = useContext(HBCookieManagerContext);
  assert(
    cookieManagerContext,
    "useIsCookieAccepted is only available inside a CookieManagerProvider",
  );

  assert(
    cookieManagerContext.isManagerUsed,
    "useShowCookieManager is only available when the cookie-manager is used",
  );

  const cookieManager = useHBCookieManager();

  return useCallback(() => cookieManager?.show?.(), [cookieManager]);
}

/** Function that opens the cookie-manager, prompting the user to select a specific cookie */
type CookieAcceptFunction = (cookieId: CookieId) => void;

/**
 * @returns A method for opening the cookie-manager to accept a specific cookie, or undefined if the cookie-manager is not available.
 */
export function useShowAcceptCookieDialog(): CookieAcceptFunction | undefined {
  const cookieManagerContext = useContext(HBCookieManagerContext);
  assert(
    cookieManagerContext,
    "useShowAcceptCookieDialog is only available inside a CookieManagerProvider",
  );

  const cookieManager = useHBCookieManager();

  const acceptFunctionInternal =
    cookieManagerContext.isManagerUsed && cookieManager?.showAcceptCookieDialog;

  return acceptFunctionInternal
    ? (cookieId) => acceptFunctionInternal({ cookieId })
    : undefined;
}
