/**
 * The HolobuilderCookieManager object
 */
export type HolobuilderCookieManager = {
  /**
   * Initializes the cookie manager.
   * Will only show the cookie manager if cookies have not been accepted yet (and shouldOpenAutomatically is true, which is the default).
   *
   * @param options Option to init with
   */
  init(options: HolobuilderCookieManagerOptions): void;

  /**
   * Check if a specific cookie has been accepted yet.
   *
   * @param id Name of the cookie to check
   */
  isCookieAccepted(id: CookieId | string): boolean;

  /**
   * TODO: Unknown purpose so far
   */
  getHtmlToAcceptCookie(id: string): string;

  /**
   * TODO: Unknown purpose so far
   */
  approveIsCookieAccepted(
    cookieId: string,
    elementId: string,
    template: string,
  ): void;

  /**
   * TODO: Unknown purpose so far
   */
  createCookieGuard(
    cookieId: string,
    elementId: string,
    acceptedHTML: string,
  ): () => void;

  /**
   * Accepts a cookie on behalf of the user.
   * Available after init has been called.
   *
   * @param id Name of the cookie to accept
   */
  acceptCookieById?(id: CookieId | string): void;

  /**
   * Show the CookieManager UI.
   * Available after init has been called.
   */
  show?(): void;

  /**
   * Shows a dialog to confirm a specific cookie.
   * Available after init has been called.
   */
  showAcceptCookieDialog?(options: ShowAcceptCookieOptions): void;
};

/** All known IDs of cookies available through the cookie-manager */
export enum CookieId {
  intercom = "intercom",
  youtube = "youtube",
  amplitude = "amplitude",
}

/** The options to initialize the HolobuilderCookieManager */
export type HolobuilderCookieManagerOptions = {
  /**
   * If true, the cookie state is pushed to the GTM dataLayer
   *
   * @default true
   */
  triggerGoogleTagManager?: boolean;

  /**
   * Disable when the babel-polyfill is already loaded before the cookie-manager
   *
   * @default true
   */
  requireBabelPolyfill?: boolean;

  /**
   * Disable when Bootstrap is already loaded before the cookie-manager
   *
   * @default true
   */
  requireBootstrap?: boolean;

  /**
   * Disable when Jquery is already loaded before the cookie-manager
   *
   * @default true
   */
  requireJquery?: boolean;

  /**
   * Delays the intialization of the cookie-manager
   *
   * @default 0
   */
  delay?: number;

  /**
   * Whether the cookie manager should open automatically if cookies have not been accepted yet
   *
   * @default true
   */
  shouldOpenAutomatically?: boolean;

  /**
   * Whether to show a preferences button
   *
   * @default false
   */
  preferencesButton?: boolean;

  /**
   * @default false
   */
  preferencesButtonOnNotComplete?: boolean;

  /**
   * Position of the preferences button
   *
   * @default "bottom right"
   */
  preferencesButtonPosition?: string;

  /**
   * @default undefined
   */
  forcedBrowserData?: BrowserData;
};

export type ShowAcceptCookieOptions = {
  /** The cookie the user will be prompted to accept. */
  cookieId: CookieId;
};

/**
 * TODO: Add docs when it's clear what this is
 */
export type BrowserData = {
  country: string;
  language: string;
};
